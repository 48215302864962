




































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { marketViewModel } from '@/modules/market/viewmodels/market-viewmodel'
import { dashboardViewModel } from '../viewmodels/dashboard-viewmodel'
import { networks } from '@/constants'

@Observer
@Component({
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
    AddWalletAddress: () => import('../dialogs/add-wallet-address.vue'),
    DashboardNoWallet: () => import('./dashboard-no-wallet.vue'),
    HoverImage: () => import('@/modules/market/components/hover-image.vue'),
    Investments: () => import('../components/Investments.vue'),
    MyOnSaleNfts: () => import('../components/MyOnSaleNfts.vue'),
    MarketItem: () => import('@/modules/market/components/market-item.vue'),
  },
})
export default class IdoPools extends Vue {
  @Inject() providers
  dashboardViewModel = dashboardViewModel
  marketViewModel = marketViewModel
  walletStore = walletStore
  networks = networks
  tab = 0

  async created() {
    dashboardViewModel.loadData()
  }

  backToDashboard() {
    this.$router.push('/dashboard')
  }

  destroyed() {
    //
  }
}
